
import logo from '../../src/assets/logo/ashok basnet.JPG';
import resume from '../resume/Ashok Basnet Resume.pdf'
export const headerData = {
    name: 'ASHOK BASNET',
    title: "Hello! I am",
    desciption: "Hello! I am Ashok Basnet, a professional and passionate programmer in my daily life. A quick learner with a self-learning attitude. I love to learn and explore new technologies and am Passionate about Problem Solving. ",
    image: logo,
    imagebw: logo,
    resumePdf: resume
}
