export const contactsData = {
    email: 'ashokbasnetofficial@gmail.com',
    phone: '+977-9863614474',
    address: 'Deumai-5, Ilam, Nepal',
    github: 'https://github.com/ashokbasnetofficial',
    facebook: 'https://www.facebook.com/ashokbasnetofficial',
    linkedIn: 'https://www.linkedin.com/in/ashok-basnet',
    twitter: 'https://twitter.com/ashokbasnet_',
    medium: '',
    stackOverflow: 'https://stackoverflow.com/users/17761727/ashok-basnet',
    devUsername: "ashokbasnetofficial"
}